import "./index.css";
import VideoFile_1_1 from "../../assets/video/1_Banner.webm";
import logo_1 from "../../assets/images/demo1/logo1.png";
import logo_2 from "../../assets/images/demo1/logo2.png";
import logo_3 from "../../assets/images/demo1/logo3.png";
import logo_4 from "../../assets/images/demo1/logo4.png";
import logo_5 from "../../assets/images/demo1/logo5.png";
import logo_6 from "../../assets/images/demo1/logo6.png";
import logo_7 from "../../assets/images/demo1/logo7.png";
import logo_8 from "../../assets/images/demo1/logo8.png";
import logo_9 from "../../assets/images/demo1/cro-logo.png";
import icon_right from "../../assets/images/demo1/right.png";

const Demo1Page = () => {
  const handleClick = type => {
    //link to /ai-agent/demo/video page
    if (type === "cc") {
      window.location.href = "https://visios.ai/agent/creator/";
    }
    if (type === "image") {
      window.location.href = "/ai-agent/demo/image";
    }
    if (type === "video") {
      window.location.href = "http://10.80.16.13:7860/";
    }
    if (type === "nocode") {
      window.location.href = "https://simplenocode.visios.ai/";
    }
    if (type === "workflow") {
      window.location.href = "https://workflow.visios.ai/";
    }
    if (type === "codebot") {
      window.location.href = "https://visios.ai/demo/codebot/";
    }
  };

  return (
    <>
      <div className={"dm-1-page"}>
        <section className={"part01"}>
          <div className={"main-box row-box"}>
            <div className={"words-box"}>
              <h1 className={"mgb-50"}>Visios AI Platforms Enhance Enterprise Efficiency</h1>
              <p className={"mgb-20"}>
                By leveraging these AI capabilities, enterprises can streamline operations, reduce costs, and improve overall efficiency. AI Platforms offer flexible solutions that can be integrated
                into existing systems, allowing businesses to harness the power of AI without extensive technical expertise or infrastructure upgrades.
              </p>
            </div>
            <div className={"media-box"}>
              <div className={"form-box"}>
                <p className={"fs26"}>Tell us about yourself and get a demo.</p>
                <div className={"form-box-2"}>
                  <div className={"input-box mgb-40"}>
                    <div className={"input-box-2"}>
                      <input type="text" placeholder={"Work email"} />
                    </div>
                  </div>

                  <div className={"input-box mgb-40"}>
                    <div className={"input-box-2"}>
                      <input type="text" placeholder={"First name"} />
                    </div>
                  </div>

                  <div className={"input-box mgb-40"}>
                    <div className={"input-box-2"}>
                      <input type="text" placeholder={"Last name"} />
                    </div>
                  </div>

                  <div className={"input-box mgb-40"}>
                    <div className={"input-box-2"}>
                      <input type="text" placeholder={"Company you work for"} />
                    </div>
                  </div>

                  <div className={"input-box mgb-10"}>
                    <div className={"input-box-2"}>
                      <input type="text" placeholder={"What sparked your interest in Visios?"} />
                    </div>
                  </div>

                  <p className={"fs14 mgb-40"}>
                    By filling out and submitting this form, you are agreeing to our &nbsp;<span style={{color: "#5F09FF"}}>Privacy Policy</span> and to receiving email communications from Visios. You
                    can <span style={{color: "#5F09FF"}}>unsubscribe</span> any time.
                  </p>

                  <button className={"-btn"}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={"part02"}>
          <div className={"main-box"}>
            <div className={"card-box"}>
              <div className={"top-box bg-1"}>
                <div className="title-box">
                  <img src={logo_1} alt="" />
                  <h3>Customer Support</h3>
                </div>
              </div>
              <div className={"cont-box"}>
                <p onClick={() => handleClick("cc")}>
                  See the Demo In Action <img src={icon_right} alt="" />
                </p>
              </div>
            </div>

            <div className={"card-box"}>
              <div className={"top-box bg-1"}>
                <div className="title-box">
                  <img src={logo_2} alt="" />
                  <h3>Workflow</h3>
                </div>
              </div>
              <div className={"cont-box"}>
                <p onClick={() => handleClick("workflow")}>
                  See the Demo In Action <img src={icon_right} alt="" />
                </p>
              </div>
            </div>

            <div className={"card-box"}>
              <div className={"top-box bg-1"}>
                <div className="title-box">
                  <img src={logo_3} alt="" />
                  <h3>No Code App Creator</h3>
                </div>
              </div>
              <div className={"cont-box"}>
                <p onClick={() => handleClick("nocode")}>
                  See the Demo In Action <img src={icon_right} alt="" />
                </p>
              </div>
            </div>

            <div className={"card-box"}>
              <div className={"top-box bg-2"}>
                <div className="title-box">
                  <img src={logo_4} alt="" />
                  <h3>Image Creator</h3>
                </div>
              </div>
              <div className={"cont-box"}>
                <p onClick={() => handleClick("image")}>
                  See the Demo In Action <img src={icon_right} alt="" />
                </p>
              </div>
            </div>

            <div className={"card-box"}>
              <div className={"top-box bg-2"}>
                <div className="title-box">
                  <img src={logo_5} alt="" />
                  <h3>Video Creator</h3>
                </div>
              </div>
              <div className={"cont-box"}>
                <p onClick={() => handleClick("video")}>
                  See the Demo In Action <img src={icon_right} alt="" />
                </p>
              </div>
            </div>

            <div className={"card-box"}>
              <div className={"top-box bg-2"}>
                <div className="title-box">
                  <img src={logo_6} alt="" />
                  <h3>Data Analytics</h3>
                </div>
              </div>
              <div className={"cont-box"}>
                <p>
                  See the Demo In Action <img src={icon_right} alt="" />
                </p>
              </div>
            </div>

            <div className={"card-box"}>
              <div className={"top-box bg-1"}>
                <div className="title-box">
                  <img src={logo_7} alt="" />
                  <h3>Embedded Software Dev</h3>
                </div>
              </div>
              <div className={"cont-box"}>
                <p onClick={() => handleClick("codebot")}>
                  See the Demo In Action <img src={icon_right} alt="" />
                </p>
              </div>
            </div>

            <div className={"card-box"}>
              <div className={"top-box bg-1"}>
                <div className="title-box">
                  <img src={logo_5} alt="" />
                  <h3>AI Video Analytics</h3>
                </div>
              </div>
              <div className={"cont-box"}>
                <p>
                  See the Demo In Action <img src={icon_right} alt="" />
                </p>
              </div>
            </div>

            <div className={"card-box"}>
              <div className={"top-box bg-1"}>
                <div className="title-box">
                  <img src={logo_8} alt="" />
                  <h3>AI Agent for Home</h3>
                </div>
              </div>
              <div className={"cont-box"}>
                <p>
                  See the Demo In Action <img src={icon_right} alt="" />
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Demo1Page;
